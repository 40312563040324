import { injectable } from "inversify";
import IFileNameValidatorService from "../IFileNameValidatorService";

@injectable()
export default class FileNameValidatorService implements IFileNameValidatorService{
    private static readonly fileNameRegEx = new RegExp('[/*<>\\\"\|\:\?]');
    
    validateName(name: string): boolean {
        return FileNameValidatorService.fileNameRegEx.exec(name)!==null;
    }

}