import { injectable } from "inversify";
import { hideZoom, zoom } from "../../redux/slices/zoomSlice";
import store from "../../redux/store";
import IZoomService from "../IZoomService";

@injectable()
export default class ZoomService implements IZoomService{
    zoom(path: string | null, sizeClass: string){
        store.dispatch(zoom({path : path, sizeClass : sizeClass}));
    }
    zoomOut(){
        store.dispatch(hideZoom());
    }

}