import BookEntry from "./BookEntry";
import IList from "../../IList";

export default class BookList implements IList<BookEntry>{
    max_results!: number;
    items! : BookEntry[];

    getItems(): BookEntry[] {
        return this.items;
    }
}