export default class LimitedSizeMap<Key, Value> {
    private keys: Key[];
    private size: number;
    private map: Map<Key, Value>;

    constructor(size: number) {
        this.size = size;
        this.keys = [];
        this.map = new Map<Key, Value>();
    }

    public set(key: Key, value: Value) {
        this.keys.push(key);
        this.map.set(key, value);
        this.cleanUp();
    }

    public get(key: Key) {
        return this.map.get(key);
    }

    public has(key: Key) {
        return this.map.has(key);
    }

    private cleanUp() {
        while (this.map.size > this.size) {
            const oldest = this.keys.shift();
            if (oldest === undefined) continue;
            this.map.delete(oldest);
        }
    }

    private delete(key:Key){
        this.map.delete(key);
        this.keys = this.keys.filter(x=>x!==key);
    }
}
