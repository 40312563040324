import { injectable } from "inversify";
import WorkerRequest from "../../models/requests/WorkerRequest";
import IWorkerRequestService, { WorkerResponseCallback } from "../IWorkerRequestService";
import WorkerResponse from "../../models/requests/WorkerResponse";
@injectable()
export default class WorkerRequestService implements IWorkerRequestService{
    
    private worker : Worker;
    private responseCallback : WorkerResponseCallback | null;

    constructor(){
        this.responseCallback = null;
        this.worker = new Worker(process.env.REACT_APP_WORKER_URL!);
        this.worker.onmessage = (e)=>{
            if(this.responseCallback!==null){
                this.responseCallback(e.data as WorkerResponse);
            }
        }
        this.worker.onerror = (e)=>{
            console.log(e);
        }
    }

    reset(): void {
         this.worker.postMessage('reset');
    }
    
    makeBatchRequest(requests: WorkerRequest[], responseCallback: WorkerResponseCallback): void {
        this.worker.postMessage({requests:requests});
        this.responseCallback = responseCallback;
    }

}