import { inject, injectable } from "inversify";
import WorkerRequest from "../../models/requests/WorkerRequest";
import type { IAuthService } from "../../services/IAuthService";
import type IWorkerRequestService from "../../services/IWorkerRequestService";
import type IGoogleStorageRESTClient from "../IGoogleStorageRESTClient";
import IImageThumbRESTClient from "../IImageThumbRESTClient";
import { RESTClient } from "./RESTClient";

@injectable()
export default class ImageThumbRESTClient extends RESTClient implements IImageThumbRESTClient{
   
    @inject('IAuthService')
    private readonly authService! : IAuthService;

    @inject('IWorkerRequestService')
    private readonly workerService! : IWorkerRequestService;

    @inject('IGoogleStorageRESTClient')
    private readonly googleRESTClient! : IGoogleStorageRESTClient;
   
    constructor(){
        super();
        this.setBaseURL('https://wod-thumb-uux56memxa-uc.a.run.app/api/v1/');
    }
    
    async getTextureBase64Batch(objectNames: Map<number, string>, callback: (id: number, data: string | null) => void){
        const token = await this.authService.getToken()
        if (token === null) return null

        const storageToken = await this.googleRESTClient.getToken(token)

        const requests : WorkerRequest[] = []

        objectNames.forEach((objectName,id)=>{
            const url = this.getFullURL(`assets/?file_name=${encodeURIComponent(objectName)}`);
            
            const request : WorkerRequest = {
                id : id,
                url : url,
                token : storageToken
            };

            requests.push(request);
        })


        this.workerService.reset()

        this.workerService.makeBatchRequest(requests, (response)=>{
            if(response.code>=200 && response.code<300)
            {
                callback(response.id as number, this.arrayBufferToBase64(response.body as ArrayBuffer));
                return;
            }
            callback(response.id as number, null);
        })

        
    }

    private arrayBufferToBase64(buffer : ArrayBuffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    }

}